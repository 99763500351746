<template>
  <div>
    <!--begin::Content header-->
    <div
        class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Already have an account?
      </span>
      <router-link
          class="font-weight-bold font-size-3 ml-2"
          :to="{ name: 'login' }"
      >
        Sign In!
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signup-->
    <div class="login-form login-signin bg-white p-20">
      <div class="text-center mb-5 mb-lg-5">
<!--        <div>-->
<!--          <a :href="siteUrl" class="flex-column-auto">-->
<!--            <img src="media/logos/main_logo.jpg" class="h-6" style="max-height: 110px" />-->
<!--          </a>-->
<!--        </div>-->
        <h3 class="font-size-h1">Sign Up</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your details to create your account
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Once you register, a confirmation email will be sent to verify your
            email address.
          </div>
        </div>
        <b-form-group
            id="example-input-group-0"
            label=""
            label-for="example-input-0"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.first_name.$model"
              :state="validateState('first_name')"
              aria-describedby="input-0-live-feedback"
              placeholder="First name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-0-live-feedback">
            First name is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.last_name.$model"
              :state="validateState('last_name')"
              aria-describedby="input-1-live-feedback"
              placeholder="Surname"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Surname is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="input-2-live-feedback"
              placeholder="Mobile Number"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Mobile Number is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-3"
            label=""
            label-for="example-input-3"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              id="example-input-3"
              name="example-input-3"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-3-live-feedback"
              placeholder="Email address"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback"  v-if="!$v.form.email.required">
            This information is required and must be valid
          </b-form-invalid-feedback>

          <b-form-invalid-feedback v-if="form.email.length>9 && !$v.form.email.isUnique">Email already taken.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-4"
            label=""
            label-for="example-input-4"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              type="password"
              id="example-input-4"
              name="example-input-4"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-4-live-feedback"
              placeholder="Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-4-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-6"
            label
            label-for="example-input-6"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6 border border-gray-800"
              type="password"
              id="example-input-6"
              name="example-input-6"
              v-model="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              aria-describedby="input-6-live-feedback"
              placeholder="Confirm Password"
          ></b-form-input>

          <b-form-invalid-feedback id="input-6-live-feedback"
          >Password should match</b-form-invalid-feedback
          >
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap flex-center">
          <button
              type="submit"
              ref="kt_login_signup_submit"
              class="btn btn-primary px-9 py-4 my-3 font-size-3 mx-4 "
          >
            Register
          </button>
          <button
              v-on:click="$router.push('login')"
              class="btn btn-standard px-9 py-4 my-3 font-size-3 mx-4"
          >
            Cancel
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->

    <!-- Recatptcha -->
    <vue-recaptcha
        ref="invisibleRecaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
    ></vue-recaptcha>
    <!-- Recaptcha -->
    <!-- Notify -->
    <vue-snotify></vue-snotify>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import VueRecaptcha from "vue-recaptcha";
import { validationMixin } from "vuelidate";
import ApiService from "@/core/services/api.service";
import userService from "@/core/services/user/UserService";
import { API_URL} from "@/core/config";
const UserService = new userService();
import {
  numeric,
  email,
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        first_name: "",
        last_name: "",
        phone:"",
        email:"",
        password: "",
        confirmPassword: "",
        type:"customer",
        account_type:"customer"
      },
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      siteUrl: API_URL,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(3),
      },
      last_name: {
        required,
        minLength: minLength(3),
      },
      phone: {
        numeric,
        required,
        minLength: minLength(10),
      },
      email: {
        required,
        email,
        async isUnique(value) {
          if (value === "") return true;
          if(value.length>10)
          {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                ApiService.post("user/validate/email", {
                  email: this.$v.form.email.$model,
                }).then((response) => {
                  resolve(response.data);
                });
              }, 500);
            });
          }

        },
      },
      password: {
        required,
        minLength: minLength(3),
      },

      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        confirmPassword: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // dummy delay
      setTimeout(() => {
        UserService.register(this.form)
            .then((response) => {
              this.$router.push({ name: "verification" });
            })
            .catch((err) => {
              if (err.response.status == 422) {
                this.$snotify.error(err.response.data.message);
              }
            });

        submitButton.classList.remove("kt-spinner");
      }, 2000);

    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
